import React, {useEffect, useState} from 'react';
import './App.css';

function App() {

    const [passwordLength, setPasswordLength] = useState(16);
    const [passwordCount, setPasswordCount] = useState(5);
    const [symbols, setSymbols] = useState([true, true, true, true]);
    const [passwords, setPasswords] = useState([]);

    const symbolsTemplate = [
        'ABCDEFGHIJKLMNOPQRSTUVWXYZ',
        'abcdefghijklmnopqrstuvwxyz',
        '0123456789',
        '!#$.%&(*)+[>/:_<]=?{@}'
    ];

    const LengthPlus = () => {
        let passlen = passwordLength;
        passlen++;
        setPasswordLength(passlen);
        updPasswords();
    }

    const LengthMinus = () => {
        let passlen = passwordLength;
        passlen--;
        if (passlen < 6) {
            passlen = 6;
        }
        setPasswordLength(passlen);
        updPasswords();
    }

    const CountPlus = () => {
        let passcnt = passwordCount;
        passcnt++;
        setPasswordCount(passcnt);
        updPasswords();
    }

    const CountMinus = () => {
        let passcnt = passwordCount;
        passcnt--;
        if (passcnt <= 0) {
            passcnt = 1;
        }
        setPasswordCount(passcnt);
        updPasswords();
    }

    function updPasswords() {

        let password = [];
        let s = '';
        for (let indx in symbols) {
            if (symbols[indx]) {
                s += symbolsTemplate[indx];
            }
        }

        for (let p = 0; p < passwordCount; p++) {
            let passw = '';
            for (var i = 0; i < passwordLength; i++) {
                passw += s.charAt(Math.floor(Math.random() * s.length));
            }
            password.push(passw);
        }

        setPasswords(password);
    }

    const changeCheckbox = (ev) => {
        const value = +ev.target.value;
        const checked = ev.target.checked;
        let s = symbols;
        s[value] = checked;
        setSymbols(s);
        updPasswords();
    }

    useEffect(() => {
        updPasswords();
    }, []);

    return (
        <div className="App">
            <div className="container">
                <div className="py-5 text-center">
                    <i className="fa fa-lock fa-5x"></i>
                    <h2>Генератор паролей</h2>
                    <p className="lead">Система поможет сгенерировать Вам устойчивые пароли</p>
                </div>

                <div className="row">
                    <div className="col-md-6 offset-md-3">
                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">Входящие группы символов</h5>

                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="0" id="defaultCheck1"
                                           onChange={changeCheckbox} checked={symbols[0]}/>
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {symbolsTemplate[0]}
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="1" id="defaultCheck2"
                                           onChange={changeCheckbox} checked={symbols[1]}/>
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {symbolsTemplate[1]}
                                    </label>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="2" id="defaultCheck3"
                                           onChange={changeCheckbox} checked={symbols[2]}/>
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {symbolsTemplate[2]}
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value="3" id="defaultCheck4"
                                           onChange={changeCheckbox} checked={symbols[3]}/>
                                    <label className="form-check-label" htmlFor="defaultCheck1">
                                        {symbolsTemplate[3]}
                                    </label>
                                </div>

                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-body">

                                <div className="row">
                                    <div className="col-md-4">
                                        <h5 className="card-title">Длина:</h5>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className="btn btn-success"
                                                    onClick={LengthMinus}>&mdash;</button>
                                            <button type="button"
                                                    className="btn btn-success">{passwordLength}</button>
                                            <button type="button" className="btn btn-success" onClick={LengthPlus}>+
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <h5 className="card-title">Паролей:</h5>
                                        <div className="btn-group" role="group" aria-label="Basic example">
                                            <button type="button" className="btn btn-warning"
                                                    onClick={CountMinus}>&mdash;</button>
                                            <button type="button" className="btn btn-warning">{passwordCount}</button>
                                            <button type="button" className="btn btn-warning" onClick={CountPlus}>+
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-md-4">
                                        <h5 className="card-title">Обновить:</h5>
                                        <button type="button" className="btn btn-primary btn-block" onClick={updPasswords}><i className='fa fa-refresh'></i></button>
                                    </div>

                                </div>


                            </div>
                        </div>

                        <div className="card mt-3">
                            <div className="card-body">
                                <h5 className="card-title">Пароли <small className="text-muted text-small">({passwordCount} паролей, длиной {passwordLength} симв.)</small> </h5>
                                <ul className="passwords">
                                    {
                                        passwords.map((p, indx) => <li key={indx}>{p}</li>)
                                    }
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>

                <footer className="my-5 pt-5 text-muted text-center text-small">
                    <p className="mb-1">&copy; 2020 Генератор паролей</p>
                    <small>Powered by React</small>
                </footer>
            </div>
        </div>
    );
}

export default App;
